















import {Vue, Component} from "vue-property-decorator";
import WalletType from "@/views/Money/Wallet/Wallet";
import HeadTop from "@/components/ShareComponent/HeadTop.vue";
import WalletHead from "@/views/Money/Wallet/components/Head/index.vue";
import WalletTitle from "@/views/Money/Wallet/components/Title/index.vue";
import WalletList from "@/views/Money/Wallet/components/List/index.vue";
import ZoomPage from "@/impView/PageSafety";
import {getAccount} from "@/views/Money/Wallet/Server";

@Component({name: "Wallet",components:{ HeadTop,WalletHead,WalletTitle,WalletList }})
export default class Wallet extends ZoomPage implements WalletType{
    data:any = {}

    mounted(){
        this.loadingShow = false
    }

    activated(){
        this.handleUpData()
    }

    handleUpData(){
        getAccount().then(res=>{
            this.data = res
        })
    }
}
