

















import {Vue, Component, Watch} from "vue-property-decorator";
import WalletListType from "./indexType";
import PullDownUpList from "@/components/ShareComponent/PullDownUp.vue"
import ListBottom from "@/components/ShareComponent/ListBottom.vue";
import WalletItem from "@/views/Money/Wallet/components/Item/index.vue";
import {getWater} from "@/views/Money/Wallet/Server";
import {WalletStore} from "@/views/Money/Wallet/Store";


@Component({name: "WalletList",components:{ PullDownUpList,ListBottom,WalletItem }})
export default class WalletList extends Vue implements WalletListType{
    PullDown = false
    UpDownBool = false
    StopUp = false
    List:any[] = []

    activated(){
        if (this.getList.length ) this.List = this.getList;
        else {
            this.UpDownBool = true
        }
    }

    handlePullDown(){
        let upData = WalletStore.getWalletUpData;
        upData.pageNo = 1;
        WalletStore.SetWalletUpData(upData)
        this.PullDown = true
        this.StopUp = false
        this.handleUpData(true)
    }

    handleUpDown(){
        if ( this.StopUp )return
        let upData = WalletStore.getWalletUpData;
        upData.pageNo += 1;
        WalletStore.SetWalletUpData(upData)
        this.UpDownBool = true
        this.handleUpData()
    }

    handleUpData(bool=false){
        getWater().then(res=>{
            this.PullDown = false
            this.UpDownBool = false
            if ( res.length < WalletStore.getWalletUpData.pageSize )this.StopUp = true;
            if ( bool )
                WalletStore.SetWalletList( res );
            else
                WalletStore.SetWalletList( WalletStore.getWalletList.concat(res) );
        })
    }

    get getList(){ return WalletStore.getWalletList }
    @Watch("getList")
    changeList(newVal:any[]){
        this.List = newVal
    }

    get getNavBool(){ return WalletStore.getWalletFilterBool }
    @Watch("getNavBool")
    changeNavBool(newVal:boolean){
        if ( newVal ){
            this.StopUp = false
            this.UpDownBool = true
            this.handleUpData(true)
        }
    }
}
