




























import {Vue, Component, Prop} from "vue-property-decorator";
import WalletItemType from "./indexType";

@Component({name: "WalletItem"})
export default class WalletItem extends Vue implements WalletItemType{
    RightIcon = require("@/assets/icon/Basics/Right.png");

    handleToPath(){
        let name = "",
            query = {}
        if ( this.getData && this.getData.type == 1 ){
            name = "billSuc"
            query = { data:JSON.stringify(this.getData || {}) }
        } else if ( this.getData && this.getData.type == 2 ){
            name = "billStatus"
            query = { state : "off",data:JSON.stringify(this.getData || {}) }
        } else {
            name = "billStatus"
            query = { state : "ok",data:JSON.stringify(this.getData || {}) }
        }
        this.$router.push({
            name, query
        })
    }

    @Prop(Object)
    data!:any
    get getData(){
        return this.data
    }

    @Prop(Number)
    index!:number
    get getIndex(){
        return this.index
    }
}
