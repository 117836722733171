




















import {Vue, Component} from "vue-property-decorator";
import WalletTitleType from "./indexType";
import ShowSelDay from "@/views/Money/components/ShowSelDay/index.vue";
import {WalletStore} from "@/views/Money/Wallet/Store";

@Component({name: "WalletTitle",components:{ ShowSelDay }})
export default class WalletTitle extends Vue implements WalletTitleType{
    calendarIcon = require("@/views/Money/Img/calendar.png")
    show = false
    endTime = ""
    startTime = ""

    handleGetData(e: any): void {
        this.startTime = e.start.split(" ")[0]
        this.endTime = e.end.split(" ")[0]
        let upData = WalletStore.getWalletUpData;
        upData.pageNo = 1;
        upData.startDate = e.start
        upData.endDate = e.end
        WalletStore.SetWalletUpData( upData )
        WalletStore.SetWalletFilterBool(true)
    }

    handleGetShow(e: any): void {
        this.show = e
    }

}
