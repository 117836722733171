






























import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import WalletHeadType from "@/views/Money/Wallet/components/Head/indexType";
import { depositList } from "@/views/Money/Wallet/Model";

@Component({name: "WalletHead"})
export default class WalletHead extends Vue implements WalletHeadType{
    depositList = depositList

    activated(){
        this.initData()
    }

    initData(){
        if ( this.getData && this.getData["totalWithdrawal"] ){
            this.depositList[0]['money'] = ("￥ " + this.getData["totalWithdrawal"] || 0) as string
        }else if ( this.getData["successWithdrawal"] ){
            this.depositList[1]['money'] = ("￥ " + this.getData["successWithdrawal"] || 0) as string
        }else if ( this.getData["ongoingWithdrawal"] ){
            this.depositList[1]['money'] = ("￥ " + this.getData["ongoingWithdrawal"] || 0) as string
        }
    }

    @Prop(Object)
    data!:any
    get getData(){ return this.data }
    @Watch("getData")
    changeData(newVal:any){
        this.initData()
    }
}
